import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import {
  IOrder,
  OrderDeliveryMethod,
  OrderService,
  OrderStatus,
  OrderWooCommerceShippingMethod,
} from '@Services/Order';

import { OrderTableProps } from './OrderTable.props';

export const OrderTable = ({
  className,
  filters,
  type,
  status,
  children,
  actions,
}: OrderTableProps) => {
  const { t } = useTranslation();
  const currentStatus = status[0].value;

  const showColumns =
    currentStatus !== OrderStatus.PROCESSING && currentStatus !== OrderStatus.APPROVED;

  return (
    <Table.Resource<IOrder>
      actions={actions}
      filters={
        status.length > 0
          ? {
              ...filters,
              status,
            }
          : filters
      }
      service={OrderService}
      className={className}>
      <Table.Field.Text
        filterable
        sortable
        source="orderNr"
        label={t('ORDER.LABELS.ORDERNR')}
        width={1}
      />

      {showColumns && (
        <Table.Field.Text
          source="shippingDetails.companyName"
          label={t('ORDER.LABELS.COMPANY_NAME')}
          width={1}
          filterable
        />
      )}

      <Table.Field.Text
        source="partner.name"
        label={t('ORDER.LABELS.PARTNER')}
        width={1}
        filterable
      />

      <Table.Field.Function
        source="orderedProducts"
        label={t('ORDER.LABELS.ORDERED_PRODUCTS')}
        render={(record: IOrder) => {
          const products = record.orderedProducts.map((product, idx) => (
            <div key={idx} className="text-small">
              {product}
            </div>
          ));

          return <>{products}</>;
        }}
      />

      <Table.Field.Function
        source="note"
        label={t('ORDER.LABELS.NOTE')}
        className="text-small prewrap"
        render={(v) => v.note.replaceAll('\n', ' ')}
      />

      {showColumns && (
        <Table.Field.Text source="address" label={t('ORDER.LABELS.ADDRESS')} width={1} />
      )}

      {['Compose'].includes(type) && (
        <Table.Field.Enum
          sortable
          name="ORDER.SHIPPING_METHOD"
          source="wooCommerceShippingMethod"
          label={t('ORDER.LABELS.SHIPPING')}
          enumeration={OrderWooCommerceShippingMethod}
          width={1}
        />
      )}

      {['Finished'].includes(type) && (
        <Table.Field.Enum
          sortable
          name="ORDER.DELIVERY_METHOD"
          source="deliveryMethod"
          label={t('ORDER.LABELS.DELIVERY')}
          enumeration={OrderDeliveryMethod}
          width={1}
        />
      )}

      {showColumns && (
        <Table.Field.Enum
          name="ORDER.STATUS"
          source="status"
          label={t('ORDER.LABELS.STATUS')}
          enumeration={OrderStatus}
          width={1}
        />
      )}

      {children}
    </Table.Resource>
  );
};
